import * as React from "react"
import Layout from "../components/layout"

export default function IndexPage() {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="px-4 py-8 m-auto w-full lg:w-1/2">
          <div className="text-4xl lg:text-6xl mb-4">Fars dag</div>
          <div className="text-xl">
            <br />
            <p>FÖRRÄTT</p>
            <b>Toast Skagen</b>
            <br />
            med räkor, créme fraiche & majonnäs toppat med kräftstjärtar
            <br />
            <div class="text-base">alternativt</div>
            <p>
              <b>Smörstekt grön sparris</b>
              <br />
              inrullad i parmaskinka på salladsbädd med parmesanost
            </p>
            <p>HUVUDRÄTT</p>
            <b>Filé Mignon Black & White</b>
            <br />
            oxfilé & fläskfilé med bearnaisesås & rödvinssås
            <br />
            <div class="text-base">alternativt</div>
            <p>
              <b>Smörstekt rödingfilé</b>
              <br />
              med vitvinssås & röd tångcaviar
            </p>
            <p>DESSERT</p>
            <p>
              <b>Panna Cotta </b>
              <br />
              italiensk puddingdessert som serveras med bär
            </p>
            <b>Två rätter 309 kr</b>
            <br />
            <b>Tre rätter 369 kr</b>
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}
